export const NEW_LOGIN = 'new'
export const EXISTING_LOGIN = 'existing'

export const PHONE_TOGGLE = 'phone'
export const EMAIL_TOGGLE = 'email'

export const NUMBER = 'number'
export const CODE = 'code'

export const PHONE_SIGN_IN_BTN_ID = 'phone-sign-in-btn-id'
