import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import googleSVG from '@/public/images/google.svg'

const Button = styled('button', {
    backgroundColor: '$white',
    color: '$dark',
    fontSize: '15px',
    border: '2px solid $mediumGray',
    borderRadius: '5px',
    height: '44px',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    '&:active': {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,.2)',
    },
})

const Label = styled('span', {
    display: 'block',
})

interface ButtonProps {
    onClick: () => void
    label: string
}

export default function GoogleButton({ onClick, label }: ButtonProps) {
    return (
        <Button onClick={() => onClick()}>
            <Image src={googleSVG} alt="Google Icon" width={18} height={18} />

            <Label>{label}</Label>
        </Button>
    )
}
