import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { EXISTING_LOGIN, NEW_LOGIN, PHONE_TOGGLE } from './LoginConstants'
import { useRouter } from 'next/router'

type TLoginContextState = {
    loginType: string
    setLoginType: Function
    emailPhoneToggle: string
    setEmailPhoneToggle: Function
    showVerificationScreen: boolean
    setShowVerificationScreen: Function
    phoneVerificationText: string
    setPhoneVerificationText: Function
    actionText: string
    sentFrom: string
    requiresKYC: boolean
    isOverLimits: boolean
    setIsOverLimits: Function
    isAdaChat: boolean
}

export const LoginContext = createContext<TLoginContextState | null>(null)

export const useLoginContext = (): TLoginContextState => {
    const context = useContext(LoginContext)

    if (!context) {
        throw new Error('Please use LoginContext in parent component')
    }

    return context
}

type TLoginProviderProps = {
    children: ReactNode
}

export const LoginProvider = ({ children }: TLoginProviderProps) => {
    const [loginType, setLoginType] = useState(EXISTING_LOGIN)
    const [emailPhoneToggle, setEmailPhoneToggle] = useState(PHONE_TOGGLE)
    const [showVerificationScreen, setShowVerificationScreen] = useState(false)
    const [phoneVerificationText, setPhoneVerificationText] =
        useState('Verify and Redeem')
    const [sentFrom, setSentFrom] = useState('')
    const [requiresKYC, setRequiresKYC] = useState<boolean>(false)
    const [isOverLimits, setIsOverLimits] = useState<boolean>(false)
    const router = useRouter()
    const isAdaChat: boolean = !!router?.query?.ada

    useEffect(() => {
        const urlParams = new URLSearchParams(window?.location?.search)
        setIsOverLimits(!!urlParams.get('overLimits'))
        setRequiresKYC(!!urlParams.get('kyc'))
        setSentFrom(urlParams?.get('type') ?? '')
    }, [])

    const value = useMemo(
        () => ({
            loginType,
            setLoginType,
            emailPhoneToggle,
            setEmailPhoneToggle,
            showVerificationScreen,
            setShowVerificationScreen,
            actionText: loginType === NEW_LOGIN ? 'Sign Up' : 'Log In',
            phoneVerificationText,
            setPhoneVerificationText,
            sentFrom,
            requiresKYC,
            isOverLimits,
            setIsOverLimits,
            isAdaChat,
        }),
        [
            loginType,
            emailPhoneToggle,
            showVerificationScreen,
            phoneVerificationText,
            sentFrom,
            requiresKYC,
            isOverLimits,
            isAdaChat,
        ]
    )

    return (
        <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
    )
}
