import Button from '@/src/common/components/marketing/buttons/Button'
import { styled } from '@/src/stitches.config'
import Link from 'next/link'

const Container = styled('div', {
    width: '100%',
    maxWidth: 548,
    margin: '0 auto',
})

const Header = styled('h2', {
    fontWeight: 900,
    fontSize: '20px',
    marginBottom: '1em',
})

const SocialButtons = styled('div', {
    display: 'flex',
    fd: 'column',
    jc: 'space-evenly',
    gap: '12px',
    '@media (min-width: 535px)': {
        fd: 'row',
    },
})

const PerfectgiftLoginText = styled('div', {
    marginTop: '10px',
    textAlign: 'center',
    fonstSize: '12px'
})

const Separator = styled('div', {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 3fr',
    textAlign: 'center',
    margin: '32px auto',
})

const Line = styled('hr', {
    height: '2px',
    width: '100%',
    background: '$light',
    border: 'none',
})

const PhoneAndEmailButtons = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    marginBottom: '20px',
})

const BlueLink = styled('h6', {
    color: '$secondary',
    fontSize: '20px',
    textDecoration: 'underline',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2rem auto',
})

export const StyledH6 = styled('h6', {
    textAlign: 'center',
    my: 20,
    button: {
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        color: '$secondary',
        textDecoration: 'underline',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
    },
    a: {
        color: '$secondary',
        textDecoration: 'underline',
        cursor: 'pointer',
        ml: 5,
    },
})

const ButtonLongText = styled('span', {
    display: 'none',
    '@media (min-width: 420px)': {
        display: 'block',
    },
    '@media (min-width: 765px)': {
        display: 'none',
    },
    '@media (min-width: 830px)': {
        display: 'block',
    },
})

const ButtonShortText = styled('span', {
    display: 'block',
    '@media (min-width: 420px)': {
        display: 'none',
    },
    '@media (min-width: 765px)': {
        display: 'block',
    },
    '@media (min-width: 830px)': {
        display: 'none',
    },
})

const ToggleButton = ({
    shortLabel,
    longLabel,
    active,
    onClick,
}: {
    shortLabel: string
    longLabel: string
    active: boolean
    onClick: Function
}) => {
    return (
        <Button
            label=""
            size="sm"
            variant="primaryOutline"
            css={{
                backgroundColor: active ? '$primaryLight' : '$white',
                borderRadius: '4px',
            }}
            onClick={() => onClick('phone')}
        >
            <ButtonLongText>{longLabel}</ButtonLongText>
            <ButtonShortText>{shortLabel}</ButtonShortText>
        </Button>
    )
}

const Disclosure = () => {
    const privacyURL = 'https://policies.google.com/privacy?hl=en-US'
    const termsURL = 'https://policies.google.com/terms?hl=en-US'

    return (
        <p
            style={{
                fontSize: '12px',
                textAlign: 'center',
                padding: '2px 12px',
                marginTop: '20%',
            }}
        >
            This site is protected by reCAPTCHA and the <br />
            Google{' '}
            <a
                href={privacyURL}
                target="_open"
                style={{ color: 'var(--colors-primary' }}
            >
                Privacy Policy
            </a>{' '}
            and{' '}
            <a
                href={termsURL}
                target="_open"
                style={{ color: 'var(--colors-primary' }}
            >
                Terms of Service
            </a>{' '}
            apply.
        </p>
    )
}

const CorporateLogin = () => {
    return (
        <StyledH6>
            Need to access your corporate account?{' '}
            <Link href={'https://corp.perfectgift.com/login'} passHref>
                Log In
            </Link>
        </StyledH6>
    )
}

export {
    Container,
    Header,
    SocialButtons,
    Separator,
    Line,
    PhoneAndEmailButtons,
    BlueLink,
    PerfectgiftLoginText,
    Disclosure,
    Button,
    ToggleButton,
    CorporateLogin,
}
