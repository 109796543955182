import { TextInputWithLabel } from '@/src/common/components/elements/Forms/TextInputWithLabel'
import { GradientText } from '@/src/common/components/marketing/heros/HomepageHeroGreetingCards'
import { clearFormErrorForField } from '@/src/common/utilities/clearFormErrorForField'
import { inputHasError } from '@/src/common/utilities/inputHasError'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { FormErrorDisplay } from '@/src/modules/build-a-card/FormErrorDisplay'
import { styled } from '@stitches/react'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import Image from 'next/image'
import { BaseSyntheticEvent, useEffect, useState } from 'react'
import * as yup from 'yup'
import { emailSignIn, emailSignUp, resetPassword } from './FirebaseAuth'
import { EXISTING_LOGIN, NEW_LOGIN } from './LoginConstants'
import { useLoginContext } from './LoginContext'
import { BlueLink, Button } from './StyledComponents'

const FormField = styled('div', {
    marginBottom: '12px',
})

const emailSchema = yup.object({
    email: yup
        .string()
        .trim()
        .required('An email address is required')
        .email('A valid email address is required'),
})

const passwordSchema = yup.object({
    password: yup
        .string()
        .trim()
        .required('A password is required')
        .min(8, 'Password must be at least 8 characters'),
})

const emailPasswordValidationSchema = emailSchema.concat(passwordSchema)

type TEmailProps = {
    setIsLoginSuccess?: Function
}

const EmailPassword = ({ setIsLoginSuccess }: TEmailProps) => {
    const { loginType, showVerificationScreen, setShowVerificationScreen } =
        useLoginContext()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [visiblePassword, setVisiblePassword] = useState(false)
    // const [optIn, setOptIn] = useState(true)
    const [resentVerification, setResentVerification] = useState(false)
    const [sentPasswordReset, setSentPasswordReset] = useState(false)
    const [loading, setLoading] = useState(false)

    const [emailPasswordFormErrors, setEmailPasswordFormErrors] = useState<
        yup.ValidationError[]
    >([])

    useEffect(() => {
        setEmail('')
        setPassword('')
        setEmailPasswordFormErrors([])
    }, [loginType])

    const { user } = useUser()

    useEffect(() => {
        if (user?.email && !user.emailVerified) {
            setEmail(user.email)
            setShowVerificationScreen(true)
        }
    }, [user])

    const baseLogin = async (userType: string) => {
        const validForm = await emailPasswordValidationSchema.isValid(
            { email, password },
            { abortEarly: false }
        )

        if (!validForm) {
            return emailPasswordValidationSchema
                .validate({ email, password }, { abortEarly: false })
                .then(() => setEmailPasswordFormErrors([]))
                .catch((e) => setEmailPasswordFormErrors(e.inner))
        }

        setLoading(true)

        let loginError

        if (userType === NEW_LOGIN) {
            const res = await emailSignUp({ email, password })

            if (res?.error) {
                loginError = res.error
            }
        }

        if (userType === EXISTING_LOGIN) {
            const res = await emailSignIn({
                email,
                password,
            })

            if (res?.error) {
                loginError = res.error
            }
        }

        if (loginError) {
            setEmailPasswordFormErrors([loginError])
            setLoading(false)
            return
        }

        const { currentUser } = getAuth()

        if (currentUser && !currentUser.emailVerified) {
            await sendEmailVerification(currentUser)

            setShowVerificationScreen(true)
        }

        setIsLoginSuccess && setIsLoginSuccess(true)
        setLoading(false)
    }

    const handleForgotPassword = async () => {
        const validForm = await emailSchema.isValid(
            { email },
            { abortEarly: false }
        )

        if (!validForm) {
            return emailSchema
                .validate({ email }, { abortEarly: false })
                .then(() => setEmailPasswordFormErrors([]))
                .catch((e) => setEmailPasswordFormErrors(e.inner))
        }

        await resetPassword(email)
            .then(() => {
                setSentPasswordReset(true)
            })
            .catch((e) => setEmailPasswordFormErrors(e.inner))
    }

    const resendVerificationEmail = async () => {
        const { currentUser } = getAuth()

        if (currentUser && !currentUser.emailVerified) {
            await sendEmailVerification(currentUser)

            setShowVerificationScreen(true)
        }

        setResentVerification(true)
    }

    return (
        <>
            {!showVerificationScreen ? (
                <>
                    <FormField>
                        <TextInputWithLabel
                            id={'email'}
                            type={'text'}
                            value={email}
                            autoComplete={'email'}
                            label={'Email Address'}
                            onChange={(e: BaseSyntheticEvent) => {
                                let trimmedValue = e.currentTarget.value.trim()
                                setEmail(trimmedValue)
                                clearFormErrorForField({
                                    path: 'email',
                                    formErrors: emailPasswordFormErrors,
                                    setFormErrors: setEmailPasswordFormErrors,
                                })
                            }}
                            maxLength={50}
                            hasError={inputHasError(
                                emailPasswordFormErrors,
                                'email'
                            )}
                        />
                        <FormErrorDisplay
                            formErrors={emailPasswordFormErrors}
                            path={'email'}
                        />
                    </FormField>

                    <FormField>
                        <div style={{ position: 'relative' }}>
                            <TextInputWithLabel
                                id={'password'}
                                type={visiblePassword ? 'text' : 'password'}
                                value={password}
                                label={'Password'}
                                onChange={(e: BaseSyntheticEvent) => {
                                    let trimmedValue =
                                        e.currentTarget.value.trim()
                                    setPassword(trimmedValue)
                                    clearFormErrorForField({
                                        path: 'password',
                                        formErrors: emailPasswordFormErrors,
                                        setFormErrors:
                                            setEmailPasswordFormErrors,
                                    })
                                }}
                                style={{
                                    fontSize:
                                        password && !visiblePassword
                                            ? '30px'
                                            : '15px',
                                }}
                                maxLength={50}
                                hasError={inputHasError(
                                    emailPasswordFormErrors,
                                    'password'
                                )}
                                className={'fs-mask fs-exclude'}
                            />
                            <Image
                                src={
                                    visiblePassword
                                        ? '/images/eye-outline.svg'
                                        : '/images/eye-off-outline.svg'
                                }
                                onClick={() => setVisiblePassword((x) => !x)}
                                alt={'Password Eye'}
                                height={36}
                                width={36}
                                style={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '16px',
                                }}
                            />
                        </div>
                        <FormErrorDisplay
                            formErrors={emailPasswordFormErrors}
                            path={'password'}
                        />
                    </FormField>

                    {/*
                        Email marketing opt-in was removed from the login form design,
                        keeping this here in case it gets added back.
                        This email opt-in is not saved or linked to anything.
                    */}
                    {/* <div
                        style={{
                            display: 'flex',
                            gap: '1rem',
                            margin: '1.5rem auto',
                            fontSize: '14px',
                        }}
                    >
                        <input
                            id="opt-in"
                            name="opt-in"
                            type="checkbox"
                            checked={optIn}
                            onChange={(e: BaseSyntheticEvent) =>
                                setOptIn(e.target.checked)
                            }
                        />

                        <label htmlFor="opt-in">
                            Sign up for email promotions, discounts &
                            announcements.
                        </label>
                    </div> */}

                    {loginType === EXISTING_LOGIN && (
                        <>
                            {!sentPasswordReset ? (
                                <BlueLink onClick={handleForgotPassword}>
                                    Forgot password?
                                </BlueLink>
                            ) : (
                                <h4
                                    style={{
                                        textAlign: 'center',
                                        margin: '40px auto',
                                    }}
                                >
                                    An email has been sent with a link to reset
                                    your password!
                                </h4>
                            )}
                        </>
                    )}

                    <Button
                        label={loginType === NEW_LOGIN ? 'Sign Up' : 'Log In'}
                        showArrow={true}
                        width="full"
                        textTransform="uppercase"
                        disabled={loading}
                        onClick={() => baseLogin(loginType)}
                    />
                </>
            ) : (
                <div>
                    <h2 style={{ fontSize: '2.1rem', wordWrap: 'break-word' }}>
                        We sent a verification email to<br/>
                        <GradientText>{email}</GradientText>
                    </h2>

                    <p>
                        Please click on the verification link that we sent to
                        your email address and return to this window.
                    </p>

                    {!resentVerification ? (
                        <BlueLink onClick={resendVerificationEmail}>
                            {"Didn't receive your activation code?"}
                        </BlueLink>
                    ) : (
                        <h4
                            style={{ textAlign: 'center', margin: '40px auto' }}
                        >
                            A new verification email has been sent!
                        </h4>
                    )}

                    <br />

                    <div
                        style={{
                            display: 'flex',
                            gap: '1rem',
                            margin: '2rem auto',
                            fontSize: '14px',
                        }}
                    >
                        <p>
                            PerfectGift wants your gift experience to be as safe
                            and secure as possible. To facilitate that, we
                            require an account to be signed into or created to
                            ensure you are the only person accessing your gift
                            and gift details. Please see our{' '}
                            <a
                                href={
                                    'https://www.perfectgift.com/terms-of-use'
                                }
                                target="_open"
                                style={{ color: 'var(--colors-primary' }}
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href={
                                    'https://www.perfectgift.com/privacy-policy'
                                }
                                target="_open"
                                style={{ color: 'var(--colors-primary' }}
                            >
                                Privacy Policy
                            </a>{' '}
                            for more details about our policies.
                        </p>
                    </div>
                </div>
            )}
        </>
    )
}

export default EmailPassword
